(()=>{
  /**
   *
   * TIMEFORMAT CLASS
   *
   */
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;
  /**
   *
   */
  class TimeFormat{}
  TimeFormat.changeToStamp = function(){

  }
  /**
   *
   */
  TimeFormat.s2audio = function(t){
    t = Math.ceil(t);
    // t = t * 10;
    let h = ('0'+(t / 3600 | 0)).slice(-2);
    let m = ('0'+(t % 3600 / 60 | 0)).slice(-2);
    let s = ('0'+(Math.ceil(t % 60))).slice(-2);
    let result;
    if(h === '00'){
      result = `${m}:${s}`;
    }else{
      result = `${h}:${m}:${s}`;
    }
    return result;
  }
  _c.TimeFormat = TimeFormat;
})();

(()=>{
  /**
   *
   * AUDIO CLASS
   *
   * 以下のライブラリに依存
   *
   * - jQuery
   *
   * @param    {string}  path              - オーディオのパス
   * @param    {string}  id                - ID
   * @param    {string}  [group = false]   - mediagroup属性に設定する値。なければ設定しない
   * @param    {jQuery}  [$parent = false] - 親となるjQueryオブジェクト。なければHTMLに追加しない
   * @property {boolean} isLoaded          - 読み込みが完了してるか
   * @property {boolean} isPlay            - 再生中か
   * @property {boolean} isPause           - 停止中か
   * @property {number}  volume            - オーディオのボリューム
   * @property {DOM}     audio             - オーディオ要素
   * @property {jQuery}  $audio            - オーディオ要素のjQueryオブジェクト
   */
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;

  // ///////////////////////////////////////////////////////////////////////////
  //
  // CLASS
  //
  // ///////////////////////////////////////////////////////////////////////////
  class Audio{
    /**
     * @return {jQueryPromise}
     */
    constructor(path, id, group = false, $parent = false, preload = 'auto'){
      this.isLoaded      = false;
      this.isPlay        = false;
      this.isPause       = true;
      this.id            = id;
      this.$audio        = $('<audio>');
      this.audio         = this.$audio[0];
      this.audio.preload = preload;

      Object.defineProperties(this, {
        time : {
          get : ()=>{ return this.audio.currentTime },
          set : (val)=>{ this.audio.currentTime = val }
        },
        volume : {
          get : ()=>{ return this.audio.volume },
          set : (val)=>{ this.audio.volume = val }
        },
        muted : {
          get : ()=>{ return this.audio.muted },
          set : (val)=>{ this.audio.muted = val }
        },
        duration : {
          get : ()=>{ return this.audio.duration },
          set : ()=>{}
        }
      });

      let def       = new $.Deferred();
      if($parent) $parent.append(this.$audio);
      if(group){
        this.$audio.attr('mediagroup', this.group);
        this.audio.mediagroup = this.group;
      }
      this.audio.src = path;
      this.$audio.on('canplaythrough', ()=>{
        this.isLoaded = true;
        this.$audio.off('canplaythrough');
        def.resolve(id, this);
      });
      this.$audio.on('loadedmetadata', ()=>{
        this.audio.currentTime = 0;
      });
      this.audio.load();
      return def.promise();
    }
    /**
     * ボリュームの変更
     */
    changeVolume(){

    }
    /**
     * オーディオの再生
     */
    play(){
      if(this.isPlay) return false;
      this.isPause = false;
      this.isPlay  = true;
      this.audio.play();
    }
    /**
     * オーディオの停止
     */
    pause(){
      if(this.isPause) return false;
      this.isPause = true;
      this.isPlay  = false;
      this.audio.pause();
    }
    /**
     *
     */
    return(){
      this.pause();
      this.time = 0;
    }
    /**
     *
     */
    replay(){
      this.pause();
      this.time = 0;
      this.play();
    }
  }
  _c.Audio = Audio;
})();

(()=>{
  /**
   *
   * VIDEO CLASS
   *
   * 以下のライブラリに依存しています。
   *
   * - jQuery
   * - ua-parser-js
   * - Caption
   * - TimeFormat
   * - VideoCaring
   *
   * ua-parser-js
   * : https://github.com/faisalman/ua-parser-js
   *
   * @param {string}         args.id               - #から始まるvideo要素のid
   * @param {srring}         args.dir              - 動画やキャプションなどが入っているディレクトリのパス
   * @param {object<string>} args.audioList        - キーが画面に表示する人物名、値がその人物のID
   * @param {string}         args.videoName        - 動画の名前
   * @param {object}         [args.caption]        - キャプション情報、キーが画面に表示するキャプション名(基本的に言語)になる
   * @param {array<string>}  [args.caption["key"]] - [0]がキャプションID(jaとかen) [1]がキャプションファイル名
   * ---------------------------------------------------------------------------
   * @property {videoCaring}              carer                   - ステートを変更した際に意図しない挙動をするブラウザ向けの介護人
   * @property {string}                   windowState             - windowの状態 (focusとかblurとか)
   * @property {string}                   name                    - 変更不可
   * @property {number}                   volume                  - 音量(0~1の数値)
   * @property {number}                   time                    - 再生時間(秒)
   * @property {number}                   duration                - 動画時間(秒) 変更不可
   * @property {number}                   readyState              - video.readyState 変更不可
   * @property {string}                   state                   - 動画の状態を表す
   *                                                                {
   *                                                                  prep    : 準備中,
   *                                                                  playing : 再生中,
   *                                                                  loading : 動画読み込み中,
   *                                                                  pausing : 停止中,
   *                                                                  seeking : シーキング,
   *                                                                  ended   : 再生終了
   *                                                                }
   * @property {string}                   prevState               - １つ前の動画の状態を表す {prep : 準備中, playing : 再生中, loading : 動画読み込み中, pausing : 停止中, end : 再生終了}
   * @property {boolean}                  stateLock               - stateの変更をロックする
   * @property {string}                   mode                    - 動画再生モード canvasかvideo 変更不可
   * @property {object}                   listener                - イベントのリステナ
   * @property {object}                   listener.windowFocus    - windowがfocusされたときのリステナ
   * @property {object}                   listener.windowBlur     - windowがblurされたときのリステナ
   * @property {object}                   listener.resize         - windowがリサイズされたときのリステナ 250ms間隔で実行
   * @property {object}                   listener.canplaythrough - video要素のcanplaythroughイベントのリステナ
   * @property {DOM}                      video                   - video要素
   * @property {DOM}                      canvas                  - canvas要素
   * @property {CanvasRenderingContext2D} canvasCtx               - canvasのコンテキスト
   * @property {jQuery}                   $root                   - 変更不可
   * @property {jQuery}                   $videoContainer         - 動画コンテナ
   * @property {jQuery}                   $seekBar                - 動画シークバーコンテナ
   * @property {jQuery}                   $seek                   - 動画シークバー
   * @property {jQuery}                   $video                  - 動画要素
   * @property {jQuery}                   $canvas                 - 動画要素(video代替canvas)
   * @property {jQuery}                   $playBtn                - 再生ボタン
   * @property {jQuery}                   $overlayPlayBtn         - 動画の上に乗ってる再生ボタン
   * @property {jQuery}                   $pauseBtn               - 停止ボタン
   * @property {jQuery}                   $audioSeek              - 音量シークバーコンテナ
   * @property {jQuery}                   $audioPointer           - 音量シーク位置
   * @property {jQuery}                   $audioProgress          - 音量シークバー
   * @property {jQuery}                   $nowTime                - 現在の再生時間
   * @property {jQuery}                   $endTime                - 終了時間
   * @property {jQuery}                   $captionContainer       - 字幕コンテナ
   * @property {jQuery}                   $optionContainer        - オプションコンテナ
   * @property {jQuery}                   $captionOps             - 字幕
   * @property {jQuery}                   $rubyOps                - ルビ
   * @property {jQuery}                   $audioOps               - 音声

   * @property {boolean}         isLoaded         - 読み込みが完了しているか
   * @property {boolean}         isPlay           - 再生中か
   * @property {boolean}         isPause          - 停止中か
   * @property {string}          mode             - 表示モード video | canvas
   * @property {DOM}             video            - video要素
   * @property {JQuery}          $video           - video要素のjQueryオブジェクト
   * @property {object<Audio>}   audio            - key : value で入っているAudioクラスのインスタンス
   * @property {object}          audio.seek       - シーク用のAudioインスタンス動画をaudioとして読んでいる
   * @property {object<Caption>} caption          - key : value で入っているCaptionクラスのインスタンス
   */
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;
  let $win              = null;
  let $body             = null;
  let _private          = new WeakMap();
  let get_              = (that)=>{
      return _private.get(that);
  };

  // ///////////////////////////////////////////////////////////////////////////
  //
  // イベント
  //
  // ///////////////////////////////////////////////////////////////////////////
  // ===========================================================================
  // 音声の変更
  // ===========================================================================
  let changeAudio = function(e){
    let _         = get_(this);
    let $this     = $(e.currentTarget);
    let $list     = $('label', $this.parents('ul'));
    let audioArr  = [];
    let state     = this.state;
    let prevState = this.prevState;
    let time      = this.time;
    let fileName  = null;
    // let listener  = this.listener.canplaythrough;
    $list.map((i, $list)=>{
      $list = $($list);
      let id     = $list.data('id');
      let $input = $('input', $list);
      if($input.prop('checked')) audioArr.push(id);
    });

    if(audioArr.length !== 0){
      fileName = this.name;
      audioArr.forEach((addName, i)=>{
        if(addName) fileName += `+${addName}`;
      });
      fileName += '.mp4';
    }else{
      fileName = `${this.name}.mp4`;
    }
    // 初回 --------------------------------------------------------------------
    if(state === 'prep'){
      this.src = fileName;
      this.video.load();
      if(this.mode === 'canvas'){
        this.$videoData[0].src = this.src;
        this.$videoData[0].load();
      }
      this.$video.one('canplaythrough', (e)=>{
        this.state = 'pausing';
      });
    }
    // 2回目以降 ---------------------------------------------------------------
    else{
      _.orderTime = time;
      this.pause();
      this.state = 'loading';
      this.src = fileName;
      this.video.load();
      this.$video.one('canplaythrough', (e)=>{
        if(state === 'playing'){
          this.state = 'loading';
          this.play();
        }
        if(state === 'pausing'){
          this.state = state;
          this.pause();
        }
        else if(prevState === 'playing'){
          this.state = 'loading';
          this.play();
        }else{
          this.state = state;
        }
      });
    }
  };

  // ===========================================================================
  // キャプションの変更
  // ===========================================================================
  let changeCaption = function(e){
    let $this   = $(e.currentTarget);
    let $list   = $('input', $this.parents('ul'));
    let $input  = null;
    let $label  = null;
    let name    = null;
    let id      = null;
    let $target = null;
    for(let i = 0, l = $list.length; i < l; i++){
      $input  = $($list[i]);
      $label  = $input.parents('label');
      id      = $label.data('id');
      name    = $label.data('class-name');
      $target = $(`tbody.${name}`, this.$captionContainer);
      if($input.prop('checked')){
        $target.css('display', '');
      }else{
        $target.css('display', 'none');
      }
    }
  };

  // ===========================================================================
  // ルビ ON/OFF
  // ===========================================================================
  let toggleRuby = function(e){
    let $this = $(e.currentTarget);
    let val   = $this.val();
    if(val === 'on'){
      this.$captionContainer.removeClass('no-caption');
    }else{
      this.$captionContainer.addClass('no-caption');
    }
  };

  // ===========================================================================
  // 動画の読み込みが再生に間に合わなくなった場合
  // ===========================================================================
  let videoWaiting = function(e){
    let prevState = this.state;
    let _       = get_(this);
    // // 音声変更時は何もしない
    // if(_.changeAudio) return false;
    loop.stop.call(this);
    this.state = 'loading';
  }
  // ===========================================================================
  // 動画再生中の処理
  // 再生が開始された最初に発火
  // ===========================================================================
  let videoPlaying = function(e){
    let _ = get_(this);
    this.state = 'playing';
    loop.start.call(this);
  }
  // ===========================================================================
  // 動画終了
  // ===========================================================================
  let videoEnded = function(e){
    loop.stop.call(this);
    this.state = 'ended';
    timeCheck.call(this, this.duration);
  }
  // ===========================================================================
  // 音量の変更
  // ===========================================================================
  let audioVolumeSeek = (()=>{
    let _this;
    let startMousePos;
    let startPos;
    let max;
    let $parent;
    let $progress;
    let $pointer;
    let parentW;
    let parentOffset;
    let _video;
    return function(e){
      if(e.type === 'mousedown'){
        let $this = $(e.currentTarget);
        _video        = this;
        $parnet       = $this.parents('.audio-seek-bar');
        $progress     = $('.progress', $parnet);
        $pointer      = $this;
        parentW       = $parnet.width();
        parentOffset  = $parnet.offset();
        startMousePos = e.pageX;
        startPos      = $this.css('left').replace('px', '') * 1;
        max           = parentW - $this.width();
        $body.on('mousemove', audioVolumeSeek );
        $body.one('mouseup', audioVolumeSeek );
      }
      // -----------------------------------------------------------------------
      else if(e.type === 'mousemove'){
          let mouseDiff = (startMousePos - e.pageX) * -1;
          let newPos    = startPos + mouseDiff;
          let rate      = 0;
          if(newPos < 0) newPos = 0;
          if(newPos > max) newPos = max;
          rate = (newPos / max) * 100 | 0;
          $progress.css('width', rate+'%');
          $pointer.css('left', newPos);
          _video.volume = Math.round(rate / 10) / 10;
      }
      // -----------------------------------------------------------------------
      else if(e.type === 'mouseup'){
        $body.off('mousemove', audioVolumeSeek);
      }
    }
  })();
  // ===========================================================================
  // シーク
  // ===========================================================================
  let seekHander = (()=>{
    let TimeFormat    = _c.TimeFormat;
    let rollbackState = null;
    let self          = null;
    // -------------------------------------------------------------------------
    // 再生時間の変更
    // -------------------------------------------------------------------------
    let getChangeTime = function(e){
      let _inst    = self;
      let $this    = $(e.currentTarget);
      let $seekBar = _inst.$seekBar;
      let maxVal   = _inst.$seekBar.width();
      let val      = e.clientX - $seekBar.offset().left;
      let left       = 0;
      let rtvVal     = 0;
      let duration   = _inst.duration;
      val            = val < 0 ? 0 : val > maxVal ? maxVal : val;
      rtvVal         = val / maxVal;
      left           = 100 - (rtvVal * 10000 | 0) / 100;
      _inst.$seek.css('right', `${left}%`);
      _inst.$nowTime.text(TimeFormat.s2audio(rtvVal * duration));
      return rtvVal * duration;
    //   _inst.currentTime = Math.round(rtvVal * duration);
    //   console.log(this.currentTime);
    }
    // -------------------------------------------------------------------------
    // マウスダウン
    // -------------------------------------------------------------------------
    let mousedownHandeler = function(e){
      $body.on('mousemove', mousemoveHandler );
      $body.one('mouseup', (e)=> mouseupHandler.call(this, e) );

      self = this;

      // 状態の調整
      rollbackState = this.state;

      if(this.state === 'playing') this.pause();
      this.state     = 'seeking';
      this.stateLock = true;
      getChangeTime(e);
    };
    // -------------------------------------------------------------------------
    // マウス移動
    // -------------------------------------------------------------------------
    let mousemoveHandler = (e)=>{
        getChangeTime(e);
    };
    // -------------------------------------------------------------------------
    // マウスアップ
    // -------------------------------------------------------------------------
    let mouseupHandler = function(e){
      $body.off('mousemove', mousemoveHandler );
      this.stateLock = false;
      let newTime = getChangeTime(e);
      switch(rollbackState){
          case 'playing' :
            this.play();
            this.time = newTime;
            break;
          case 'pausing' :
            this.play();
            this.time = newTime;
            setTimeout(()=>{
              this.pause();
              this.carer.pauseAssist();
            }, 200);
            // this.time = newTime;
            // console.log(this.time);
            // this.video.pause();
            // this.state = 'pausing';
            // loop.stop.call(this);
            // this.load();
            // this.$video.one('canplaythrough', (e)=>{
            //   console.log("dddd");
            // });

            // console.log(this.time);
            // this.state = 'playing';
            // this.pause();
            // this.state = rollbackState;
            break;
          case 'loading' :
            this.state = 'pausing';
            break;
          case 'ended' :
            this.state = 'pausing';
            break;
      }
        //   // 再生位置の変更
        //   this.play();
        //   this.time = newTime;
        //   console.log(rollbackState);
        //   if(rollbackState === 'pausing'){
        //       this.pause();
        //   }
    };
    // -------------------------------------------------------------------------
    return function(e){
      mousedownHandeler.call(this, e);
    }
  })();

  // ///////////////////////////////////////////////////////////////////////////
  //
  // 内部処理
  //
  // ///////////////////////////////////////////////////////////////////////////
  // 再生中のループ処理
  // ===========================================================================
  let loop = Object.create(null);

  // ループ停止 ----------------------------------------------------------------
  loop.stop = function(){
    let _ = get_(this);
    clearInterval(_.playLoopTimer);
  }

  // ループ開始 ----------------------------------------------------------------
  loop.start = function(){
    let _ = get_(this);
    _.playLoopTimer = setInterval(()=>{
      loop.func.call(this);
    }, 200);
  }

  // ループ処理 ----------------------------------------------------------------
  loop.func = function(){
    timeCheck.call(this);
    captionCheck.call(this);
  }

  // ===========================================================================
  // canvasへの描画
  // ===========================================================================
  let drawCanvas = function(){
    let w = this.canvas.width;
    let h = this.canvas.height;
    this.$videoData[0].currentTime = this.video.currentTime;
    this.canvasCtx.drawImage(this.$videoData[0], 0, 0, w, h);
  }
  // ===========================================================================
  // 再生位置、再生時間の調査
  // ===========================================================================
  let timeCheck = (function(){
    let TimeFormat = _c.TimeFormat;
    let lastTime   = null;
    return function(forceTime = false){
      let newTime = forceTime ? Math.ceil(forceTime) : Math.ceil(this.time);
      let left    = 0;
      if(newTime !== lastTime){
        lastTime = newTime;
        this.$nowTime.text(TimeFormat.s2audio(newTime));
        left = 100 - (this.time / this.duration * 10000 | 0) / 100;
        this.$seek.css('right', `${left}%`);
      }
    }
  })();

  // ===========================================================================
  // 字幕の確認
  // ===========================================================================
  let captionCheck = (function(){
    let oldTime = null;
    let nowTime = null;
    let diff;
    return function(){
      nowTime = Math.floor(this.time);
      // 同じ秒の間は処理しない
      if(oldTime === nowTime) return false;
      if(oldTime === null) oldTime = nowTime;
      diff = Math.abs(oldTime - nowTime);
      for(let key in this.caption) this.caption[key].change(nowTime, diff);
      oldTime = nowTime;
    }
  })();


  // ///////////////////////////////////////////////////////////////////////////
  //
  // CLASS
  //
  // ///////////////////////////////////////////////////////////////////////////
  class Video{
    /**
     * =========================================================================
     * コンストラクタ
     * =========================================================================
     * @return {Video}
     */
    constructor(args){
      _private.set(this, Object.create(null));
      let _       = get_(this);
      let loadNum = 0;
      if(!$body) $body = $('body');
      if(!$win)  $win  = $(window);
      _.changeAudio = false;
      _.stateLock   = false;
      this.carer    = new _c.VideoCaring(this, 100);

      this.windowState = 'focus';

      // -----------------------------------------------------------------------
      // グローバルイベントの設定
      // -----------------------------------------------------------------------
      this.listener                = Object.create(null);
      this.listener.windowFocus    = Object.create(null);
      this.listener.windowBlur     = Object.create(null);
      this.listener.resize         = Object.create(null);
      this.listener.canplaythrough = Object.create(null);

      // フォーカス / ブラー
      // let windowFocusUnfocusHandler = (e)=>{
      //   this.windowState = e.type;
      //   if(e.type === 'focus'){
      //     for(let key in this.listener.windowFocus) this.listener.windowFocus[key].call(this, e);
      //   }
      //   if(e.type === 'blur'){
      //     for(let key in this.listener.windowBlur) this.listener.windowBlur[key].call(this, e);
      //   }
      // }
      // $win.on({
      //   focus : (e)=> windowFocusUnfocusHandler(e),
      //   blur  : (e)=> windowFocusUnfocusHandler(e)
      // });
      // $win.trigger('focus');
      // $win.focus();

      // リサイズ
      $win.on('resize', (()=>{
        let timer = null;
        return (e)=>{
          if(timer) clearTimeout(timer);
          timer = setTimeout(()=>{
            for(let key in this.listener.resize) this.listener.resize[key].call(this, e);
          }, 250);
        }
      })());

      // -----------------------------------------------------------------------
      // 状態の設定
      // -----------------------------------------------------------------------
      Object.defineProperties(this, {
        stateLock : {
          get : ()=>{ return _.stateLock },
          /** @property {boolean} val */
          set : (val)=>{ _.stateLock = val },
          configurable : false
        },
        // 1つ前の状態
        prevState : {
          get : ()=>{
            return _.prevState;
          },
          set : (val)=>{
            if(this.stateLock) return;
            _.prevState = val;
          },
          configurable : false
        },
        // 新しい状態
        state : {
          get : ()=>{
            return _.state;
          },
          set : (val)=>{
            if(this.stateLock) return;
            this.prevState = _.state;
            _.state = val;
            this.$root.attr('data-state', val);
          },
          configurable : false
        }
      });

      // -----------------------------------------------------------------------
      // モードの設定
      // -----------------------------------------------------------------------
      let ua       = navigator.userAgent;
      let uaParser = new UAParser();
      let mode     = 'video';
      let uaResult;
      uaParser.setUA(ua);
      uaResult = uaParser.getResult();
      if(uaResult.device.model && uaResult.device.model.toLowerCase() === 'iphone'){
        let version = uaResult.os.version.split('.')[0] * 1;
        if(version < 10){
          mode = 'canvas';
        }
      }
      Object.defineProperty(this, 'mode', {
        value    : mode,
        writable : false, enumerable : true, configurable : false
      });

      // -----------------------------------------------------------------------
      // 動画周りのプロパティ
      // -----------------------------------------------------------------------
      Object.defineProperties(this, {
        // 動画の名前
        // 読み込むファイルの命名規則は
        // [動画の名前]+[音声の名前1]+[音声の名前1].mp4
        //
        name : {
          value    : args['videoName'],
          writable : false, enumerable : true, configurable : false
        },
        // 動画ファイルパス
        src : {
          get : ()=>{ return _.src },
          /** @param {string} val 拡張子付きのファイル名 */
          set : (val)=>{
            _.src = `${args.dir}${val}`;
            this.video.src = _.src;
          },
          configurable : false
        },
        // 動画時間
        duration : {
          get          : () =>{
            if(!_.duration) _.duration = this.video.duration;
            return _.duration;
            // return this.video.duration
          },
          set          : () =>{},
          configurable : false
        },
        // 再生位置
        time :  {
          get : () =>{ return this.video.currentTime },
          /** @param {number} val - 秒 */
          set : (val) =>{
            this.video.currentTime = val;
          },
          configurable : false
        },
        // ミュート
        muted : {
          get : () =>{ return this.video.muted },
          /** @param {boolean} val */
          set : (val) =>{ this.video.muted = val },
          configurable : false
        },
        // 音量
        volume : {
          get : () =>{ return this.video.volume },
          /** @param {number} val */
          set : (val) =>{ this.video.volume = val },
          configurable : false
        },
        // readyState のショートカット
        readyState : {
          get : () =>{ return this.video.readyState },
          set : () =>{},
          configurable : false
        }
      });

      // -----------------------------------------------------------------------
      // DOMの設定
      // -----------------------------------------------------------------------
      Object.defineProperty(this, '$root', {
        value    : $(args.id),
        writable : false, enumerable : true, configurable : false
      });
      // $rootを設定したタイミングで状態やモードを設定する
      this.$root.attr('data-mode', `${this.mode}`);
      this.state = 'prep';

      Object.defineProperties(this, {
        // 動画コンテナ
        $videoContainer : {
          value    : $('.video .container', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        // 動画シークバー
        $seekBar : {
          value    : $('.controller .video-seek-bar', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        $seek : {
          value    : $('.controller .video-seek-bar .progress', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        // 再生ボタン
        $playBtn : {
          value    : $('.controller .play', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        $overlayPlayBtn : {
          value    : $('.over-controller .play', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        // 停止ボタン
        $pauseBtn : {
          value    : $('.controller .pause', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        // 先頭に戻るボタン
        $replayBtn : {
          value    : $('.controller .replay', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        // 音量シークバー
        $audioSeek : {
          value    : $('.controller .audio-seek-bar', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        $audioPointer : {
          value    : $('.controller .audio-seek-bar i', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        $audioProgress : {
          value    : $('.controller .audio-seek-bar .progress', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        // 現在の再生時間
        $nowTime : {
          value    : $('.controller .timer .start', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        // 終了時間
        $endTime : {
          value    : $('.controller .timer .end', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        // 字幕コンテナ
        $captionContainer : {
          value    : $('.caption-container table', this.$root),
          writable : false, enumerable : true, configurable : false
        },
        // オプションコンテナ
        $optionContainer : {
          value    : $('.option-container', this.$root),
          writable : false, enumerable : true, configurable : false
        }
      });
      Object.defineProperties(this, {
        $captionOps : {
          value    : $('.caption dd ul', this.$optionContainer),
          writable : false, enumerable : true, configurable : false
        },
        $rubyOps : {
          value    : $('.ruby dd ul', this.$optionContainer),
          writable : false, enumerable : true, configurable : false
        },
        $audioOps : {
          value    : $('.audio dd ul', this.$optionContainer),
          writable : false, enumerable : true, configurable : false
        }
      });

      // イベント --------------------------------------------------------------

      // ルビの ON / OFF
      $('input[type="radio"]', this.$rubyOps).on('click', (e)=> toggleRuby.call(this, e) );
      $('input[type="radio"]:checked', this.$rubyOps).trigger('click');

      // 音量の変更 (音量シークバー)
      this.$audioPointer.on('mousedown', (e)=> audioVolumeSeek.call(this, e) );

      // 再生ボタン
      this.$playBtn.on('click', ()=>{ this.play() });
      this.$overlayPlayBtn.on('click', ()=>{ this.$playBtn.trigger('click') });
      this.$replayBtn.on('click', ()=>{ this.replay() })
      // 停止ボタン
      this.$pauseBtn.on('click', ()=>{ this.pause() });

      // 再生シークバー
      this.$seekBar.on('mousedown', (e)=> seekHander.call(this, e) );

      // -----------------------------------------------------------------------
      // ビデオ要素の設定
      // -----------------------------------------------------------------------
      // ビデオ設定
      if(this.mode === 'video'){
        Object.defineProperty(this, '$video', {
          value    : $('<video>'),
          writable : false, enumerable : true, configurable : false
        });
        Object.defineProperty(this, 'video', {
          value    : this.$video[0],
          writable : false, enumerable : true, configurable : false
        });
        this.$video.attr({
          'playsinline' : true,
          'preload'     : 'auto'
        });
        this.$videoContainer.append(this.$video);

        // イベント
        this.$video.on('click', (e)=> this.pause() );
      }

      // canvas設定
      if(this.mode === 'canvas'){
        Object.defineProperty(this, '$video', {
          value    : $('<audio>'),
          writable : false, enumerable : true, configurable : false
        });
        Object.defineProperty(this, '$videoData', {
          value    : $('<video>'),
          writable : false, enumerable : true, configurable : false
        });
        Object.defineProperty(this, 'video', {
          value    : this.$video[0],
          writable : false, enumerable : true, configurable : false
        });
        this.$video.attr({'preload' : 'auto'});
        this.$videoData.attr({'preload' : 'auto'});
        Object.defineProperty(this, '$canvas', {
          value    : $('<canvas>'),
          writable : false, enumerable : true, configurable : false
        });
        Object.defineProperty(this, 'canvas', {
          value    : this.$canvas[0],
          writable : false, enumerable : true, configurable : false
        });
        Object.defineProperty(this, 'canvasCtx', {
          value    : this.canvas.getContext('2d'),
          writable : false, enumerable : true, configurable : false
        });
        this.$videoContainer.append(this.$canvas);

        // イベント
        this.$canvas.on('click', (e)=> this.pause() );
        this.listener.resize.canvas = ()=>{
          this.canvas.width  = this.$videoContainer.innerWidth();
          this.canvas.height = this.$videoContainer.innerHeight();
        }
        setInterval(()=>{
          if(this.state !== 'playing') return false;
          drawCanvas.call(this);
        }, 30);
      }

      // 共通の処理 ------------------------------------------------------------

      // 初回読み込み終了後
      this.$video.one('canplaythrough', (e)=>{
        loadNum--;
        if(loadNum === 0) allLoaded();
      });
      loadNum++;

      this.$video.on('waiting', (e)=>{
        videoWaiting.call(this, e);
      });
      this.$video.on('playing', (e)=> videoPlaying.call(this, e) );
      this.$video.on('ended', (e)=> videoEnded.call(this, e) );

      // -----------------------------------------------------------------------
      // 音声の設定
      // -----------------------------------------------------------------------
      for(let key in args['audioList']){
        let id = args['audioList'][key];
        this.$audioOps.append(`<li><label data-id="${id}" class="d__checkbox"><input type="checkbox" checked><span>${key}</span></label></li>`);
      }
      let $audioOpsInput = $('li label input', this.$audioOps);
      $audioOpsInput.on('change', (e)=>{ changeAudio.call(this, e) });

      // 音声の変更のイベントが発生することで初回に読み込む動画ファイルが決定
      $($audioOpsInput[0]).trigger('change');

      // -----------------------------------------------------------------------
      // キャプションの設定
      // -----------------------------------------------------------------------
      let Caption = _c.Caption;
      if(args['caption']){
        loadNum += Object.keys(args['caption']).length;
        this.caption = {};

        let captionDatas = [];
        for(let key in args['caption']){
          let path      = `${args.dir}${args['caption'][key][1]}`;
          let className = args['caption'][key][0];
          captionDatas.push({
            'key'       : key,
            'path'      : path,
            'className' : className
          });
        }
        let getCaption = (datas, num)=>{
          let data = datas[num];
          new Caption(data.path, data.className, data.key, this.$captionContainer).then((id, className, instance)=>{
            this.caption[id] = instance;
            let $li = $('<li>');
            $li.append(`<label data-id="${id}" data-class-name="${className}" class="d__checkbox"><input type="checkbox" checked><span>${id}</span></label>`);
            this.$captionOps.append($li);
            loadNum--;
            if(loadNum === 0){
              allLoaded();
            }else if(datas[num+1]){
              getCaption(datas, num+1);
            }
          });
        }
        getCaption(captionDatas, 0);
      }else{
        this.$captionContainer.parents('.caption-container').remove();
        this.$captionOps.parents('.caption').remove();
        this.$rubyOps.parents('.ruby').css('padding-left', 0);
      }
      // -----------------------------------------------------------------------
      // windowの状態が変更されたときの処理
      // -----------------------------------------------------------------------
      // windowが非アクティブになったとき
      this.listener.windowBlur.videoStop = (e)=>{
        if(this.isPlay() && !window.marugoto.state.debug) this.pause();
      }
      // -----------------------------------------------------------------------
      // 初回読み込み終了後の処理
      // -----------------------------------------------------------------------
      let allLoaded = ()=>{
        let TimeFormat = _c.TimeFormat;
        this.$endTime.text(TimeFormat.s2audio(this.duration));

        // 字幕のイベント
        let $capInputs = $('input', this.$captionOps);
        $capInputs.on('change', (e)=> changeCaption.call(this, e) );
        $($capInputs[0]).trigger('change');

        this.state = 'pausing';
      }

      // -----------------------------------------------------------------------
      $win.trigger('resize');

      // loading状態にも関わらず再生が行われる場合に気付かせる。
      this.carer.continualAssist({
        when     : 'loading',
        case     : 'playing',
        expect   : 'playing',
        interval : 1000
      });
      // 動画の読み込み開始
      this.load();
    }
    /**
     * =========================================================================
     * 動画の再生
     * =========================================================================
     * ループ処理はvideoの playing イベントから呼ばれるのでここでは指定しない
     * @return {void}
     */
    play(){
      let _ = get_(this);

      // すでに再生中であれば何もしない
      // if(this.isPlay()) return this;

      this.video.play();

      // 再生時間を指定する orderTime があれば従う
      if(typeof _.orderTime === 'number' && _.orderTime !== this.time){
        this.time = _.orderTime;
      }
      _.orderTime = null;

      return this;
    }
    /**
     * =========================================================================
     * 動画の停止
     * =========================================================================
     * @return {video}
     */
    pause(){
      let _ = get_(this);

      // 再生中でなければ何もしない
      // if(!this.isPlay()) return this;

      loop.stop.call(this);
      this.video.pause();
      this.state = 'pausing';

      return this;
    }
    /**
     * =========================================================================
     * 動画の再生位置を先頭に戻す
     * =========================================================================
     * @return {video}
     */
    return(){
      // if(!this.isLoaded) return false;
      // this.pause();
      // this.play();
      // this.video.currentTime = 0;
      // for(let key in this.audio) this.audio[key].time = 0;
      // for(let key in this.caption) this.caption[key].reset();
      // this.pause();
    }
    /**
     * =========================================================================
     * 動画の再生位置を先頭に戻し、再生する
     * =========================================================================
     * @return {video}
     */
    replay(){
      let _ = get_(this);
      switch(this.state){
        case 'playing' :
          this.time = 0;
          break;
        case 'pausing' :
          this.play();
          this.time = 0;
          break;
        case 'loading' :
          this.play();
          this.time = 0;
          break;
        case 'ended' :
          this.play();
          break;
      }
    }
    /**
     * =========================================================================
     * 音量を変更する
     * =========================================================================
     * @param {number} vol - ボリュームの値、0~1のnumber
     * @return {video}
     */
    changeVolume(vol){
      this.volume = vol;
      return this;
    }
    /**
     * =========================================================================
     * 再生中か
     * =========================================================================
     * @return {boolean}
     */
    isPlay(){
      return this.state === 'playing';
    }
    /**
     * =========================================================================
     * 停止中か
     * =========================================================================
     * @return {boolean}
     */
    isPause(){
      return this.state === 'pausing';
    }
    /**
     * =========================================================================
     * 読み込み中か
     * =========================================================================
     * @return {boolean}
     */
    isLoading(){
      return this.state === 'loading';
    }
    /**
     * =========================================================================
     * 準備中か
     * =========================================================================
     * @return {boolean}
     */
    isPrep(){
      return this.state === 'prep';
    }
    /**
     * =========================================================================
     * 終了したか
     * =========================================================================
     * @return {boolean}
     */
    isEnded(){
      return this.state === 'end';
    }
    /**
     * =========================================================================
     * ステートロック状態か
     * =========================================================================
     * @return {boolean}
     */
    isStateLock(){
      return this.stateLock;
    }
    /**
     * =========================================================================
     * 字幕を持っているか
     * =========================================================================
     * @return {boolean}
     */
    hasCaption(){
      return this.caption ? true : false;
    }
    /**
     * =========================================================================
     * 動画の読み込み
     * =========================================================================
     * @return {video}
     */
    load(){
      this.video.load();
      return this;
    }
  }
  _c.Video = Video;
})();

(()=>{
  /**
   *
   * @param {video}  video    - 介護するVideoクラス
   * @param {number} interval - 介護する間隔
   */
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;
  let _private          = new WeakMap();
  let get_              = (that)=>{
      return _private.get(that);
  };

  /**
   * 一意な文字列を返す
   * @param  {String} [suffix='']
   * @param  {Number} [size=5]
   * @return {string}
   */
  let myGodparent = function(suffix = '', size = 5){
    let result = `${suffix}_`;
    let list   = [
      'a', 'b', 'c', 'd', 'e',
      'f', 'g', 'h', 'i', 'j',
      'k', 'l', 'm', 'n', 'o',
      'p', 'q', 'r', 's', 't',
      'u', 'v', 'w', 'x', 'y', 'z',
      'A', 'B', 'C', 'D', 'E',
      'F', 'G', 'H', 'I', 'J',
      'K', 'L', 'M', 'N', 'O',
      'P', 'Q', 'R', 'S', 'T',
      'U', 'V', 'W', 'X', 'Y', 'Z'
    ];
    for(let i = 0; i < size; i++){
      result += list[Math.random() * list.length >> 0];
    }
    return result;
  }
  /**
   * 継続的な介護の種類
   * when(videoが認識している状態)-case(実際の状態)-expect()
   *
   * @type {Object}
   */
  let continualAssistPattern = {
    'loading-playing-playing' : function(param){
      let video = param.video;
      if(param.timeDiff) video.state = 'playing';
    }
  }
  // ///////////////////////////////////////////////////////////////////////////
  //
  // CLASS
  //
  // ///////////////////////////////////////////////////////////////////////////
  class VideoCaring{
    /**
     * =========================================================================
     * コンストラクタ
     * =========================================================================
     * @return {VideoCaring}
     */
    constructor(video, interval){
      _private.set(this, Object.create(null));
      let _ = get_(this);
      this.video                   = video;
      this.interval                = interval;
      this.continualAssistListener = {};
    }
    /**
     */
    pauseAssist(){
      let video    = this.video;
      let interval = this.interval;
      // setTimeout(()=>{
      //   video.pause();
      //   console.log(video.state);
      //   // if()
      // }, interval);
    }
    /**
     *
     * 継続的な介護を行う
     *
     * continualAssistPattern[`${when}-${case}-${expect}`]()が呼ばれる
     *
     * @property {string} [args.name]     - 介護の名称
     * @property {string} args.when       - videoが認識している状態
     * @property {string} args.case       - 実際の状態
     * @property {string} args.expect     - 変更したい状態
     * @property {string} args.callback   -
     * @property {number} [args.interval] - 介護間隔
     * @property {object} [args.this]     - this
     * @return {name}
     */
    continualAssist(args){
      args          = args || {};
      let _         = get_(this);
      let listener  = this.continualAssistListener;
      args.name     = args.name     ? args.name     : myGodparent('assist', 5);
      args.interval = args.interval ? args.interval : this.interval;
      args.this     = args.this     ? args.this     : this;

      listener[name] = setInterval(((args)=>{
        let newTime = null;
        let oldTime = null;
        let video   = this.video;
        let state   = null;
        // ---------------------------------------------------------------------
        return ()=>{
          let ptn    = continualAssistPattern;
          let ptnKey = `${args.when}-${args.case}-${args.expect}`;
          let param  = {};
          state      = this.video.$root.attr('data-state');
          newTime    = this.video.time;
          if(oldTime === null) oldTime = newTime;
          if(args.when !== state) return false;
          param = {
            newTime  : newTime,
            oldTime  : oldTime,
            timeDiff : Math.abs(newTime - oldTime),
            video    : video
          };

          if(ptn[ptnKey]) ptn[ptnKey].call(this, param);

          oldTime = newTime;
          if(args.callback) args.callback.call(args.this);
        }
      })(args), args.interval);
    }
    /**
     *
     * 継続的な介護を停止する
     *
     * @property {string}              name
     * @return   {boolean|videoCaring}
     */
    stopContinualAssist(name){
      if(!name) return false;
      let listener = this.continualAssistListener;
      if(!listener[name]) return false;
      clearInterval(listener[name]);
      delete listener[name];
      return this;
    }
  }
  _c.VideoCaring = VideoCaring;
})();

(()=>{
  /**
   * CAPTION CLASS
   *
   * 以下のライブラリに依存しています。
   *
   * - jQuery
   */
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;
  let _appConf          = window.marugoto;

  // ///////////////////////////////////////////////////////////////////////////
  //
  // PROCESS
  //
  // ///////////////////////////////////////////////////////////////////////////
  // DATA FORMAT
  // ===========================================================================
  let dataFormat = function(){
    let arr = [];
    for(let key in this.rawData){
      let data       = this.rawData[key];
      let obj        = {};
      let timeArr    = key.split('-');

      let textFormat = (data, num)=>{
        let name   = data[0];
        let text   = data[1];
        let result;
        if(num !== 0){
           result = '<tr class="sub">';
        }else{
           result = '<tr>';
        }
        name = name.replace(/<r>/g, '<rb>');
        name = name.replace(/<\/r>/g, '</rb>');
        name = name.replace(/([^<rb>]*)(?:<\/rb>)/g, (all, g1)=>{
          all = all.replace('(', '<r><t>');
          all = all.replace(')', '</r></t>');
          return all;
        });
        result += `<th>${name}</th>`;
        text = text.replace(/<r>/g, '<rb>');
        text = text.replace(/<\/r>/g, '</rb>');
        text = text.replace(/([^<rb>]*)(?:<\/rb>)/g, (all, g1)=>{
          all = all.replace('(', '<r><t>');
          all = all.replace(')', '</r></t>');
          return all;
        });
        result += `<td>${text}</td>`;
        result += '</tr>';
        return result;
      }

      // set Time
      obj.start = timeArr[0] * 1;
      obj.end   = timeArr[1] * 1;
      // set data
      obj.data = [];
      if(typeof data[0] === 'string'){
        obj.data.push(textFormat(data, 0));
      }else{
        for(let i = 0, l = data.length; i < l; i++){
          obj.data.push(textFormat(data[i], i));
        }
      }
      arr.push(obj);
    }
    this.data = arr;
  }
  // ///////////////////////////////////////////////////////////////////////////
  //
  // CLASS
  //
  // ///////////////////////////////////////////////////////////////////////////
  class Caption{
    /**
     * @return {jQueryPromise}
     */
    constructor(path, className, id, $parent){
      let def      = new $.Deferred();
      this.pointer = 0;
      this.active  = null;
      $.ajax({
        'type'          : 'GET',
        'url'           : path,
        'dataType'      : 'jsonp',
        'jsonpCallback' : 'captionData',
        'success' : (data)=>{
          this.rawData = data;
          dataFormat.call(this);
          $parent.append(`<tbody class="${className}">`);
          this.$parent = $(`tbody.${className}`, $parent);
          def.resolve(id, className, this);
        },
        'error' : (xhr, textStatus)=>{
          if(_appConf.state && _appConf.state.debug){
            console.error(`キャプションデータ取得失敗 : ${textStatus}`);
            console.log(xhr);
            console.log('/////////////////////////////');
          }
          def.reject();
        }
      });
      return def.promise();
    }
    /**
     *
     */
    change(time, diff){
      // console.log(diff);
      let current;
      // シークなどにより再生位置が変わった場合は初期化する
      if(diff > 1){
        this.pointer = 0;
        // this.pointer = time;
        this.active  = null;
        this.$parent.empty();
      }
      // 表示しているものがあり、表示期間内であれば何もしない
      if(this.active && this.active.end > time) return false;
      // 表示しているものがあり、終了期間であれば消す
      if(this.active && this.active.end === time) this.$parent.empty();

      for(let i = this.pointer, l = this.data.length; i < l; i++){
        current = this.data[i];

        if(time >= current.start && time < current.end){
          this.pointer = i;
          this.active  = current;
          this.$parent.html(current.data);
        }
      }
    }
    /**
     *
     */
    reset(){
      this.pointer = 0;
      this.active  = null;
      this.$parent.empty();
    }
  }
  _c.Caption = Caption;
})();

(()=>{

  /**
   * 単語選択回答選択時のハンドラ
   */
  let answerClickHandler = function(e){
    let $this = $(e.currentTarget);

    if(this.$answer.hasClass('resulted')) return false;
    if($this.hasClass('checked')) return false;
    this.stateRemove();

    $this.addClass('checked');
    let selectNum = $this.data('num');
    let inputText = $this.data('answer');
    this.$input.val(inputText);
    this.$question
      .attr('data-select-num', selectNum)
      .addClass('has-val');
  }
  /**
   * 入力のredoボタンクリック時のハンドラ
   */
  let inputRedoClickHandler = function(e){
    this.stateRemove();
  }


  // ==========================================================================
  //
  // TEXT QUESTION CLASS
  //
  // 以下のライブラリに依存
  //
  // - jQuery
  //
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;

  class DefaultTextQuestion{
    /**
     * コンストラクタ
     */
    constructor($answer){
      this.$answer         = $answer;
      this.$answerBtn      = $('li', $answer);
      this.id              = this.$answer.data('answer-id');
      this.qSelector       = '.d__practice--text-input';
      this.$question       = $($(`${this.qSelector}[data-answer-id=${this.id}]`)[0]);
      this.$input          = $('input', this.$question);
      this.answerNum       = $($('input[type=hidden]', this.$answer)[0]).val() * 1;
      this.$rightAnswerBtn = $(`li[data-num=${this.answerNum}]`, this.$answer);

      this.$question.attr('data-answer-num', this.answerNum);
      $('span.fail > span', this.$question).html(this.$rightAnswerBtn.html());

      // イベント登録
      this.$answerBtn.on('click', (e)=>{
        answerClickHandler.call(this, e);
      });
      $('.redo', this.$question).on('click', (e)=>{
        inputRedoClickHandler.call(this, e);
      });
    }
    /** ------------------------------------------------------------------------
     *
     * 状態の初期化
     */
    stateRemove(num = false){
      let $question;
      let $input;
      let $answerBtn;

      $question = this.$question;
      $input    = this.$input;
      $answer   = this.$answer;
      if(num === false){
        $answerBtn = this.$answerBtn;
      }else{
        $answerBtn = $(`li[data-num=${num}]`, this.$answer);
      }
      this.$question
        .attr('data-select-num', '')
        .removeClass('has-val')
        .removeClass('fail')
        .removeClass('right');
      this.$input
        .val('');
      $answerBtn.removeClass('checked');
    }
    /** ------------------------------------------------------------------------
     *
     * やり直し
     */
    redo(){
      this.stateRemove();
      this.$answer.removeClass('resulted');
    }
    /** ------------------------------------------------------------------------
     *
     * 答え合わせ
     */
    result(){
      let $q = this.$question;
      let state;
      if($q.attr('data-select-num') && $q.attr('data-select-num') === $q.attr('data-answer-num') ){
        state = 'right';
      }else{
        state = 'fail';
      }
      $q.addClass(state);
      this.$answer.addClass('resulted');
    }
  }
  //
  _c.DefaultTextQuestion = DefaultTextQuestion;
})();

(()=>{


  let answerCtrl = {
    show : function(){
      this.$answerWrapper.addClass('float');
    },
    hide : function(){
      this.$answerWrapper.removeClass('float');
      this.$answer.css({
        'position' : '',
        'top'      : '',
        'left'     : ''
      })
    },
    pos : function($question){
      answerCtrl.show.call(this);
      let y,x;
      let $cntWrap = $question.parents('*[data-js-anchor="cnt"]');
      this.$answerWrapper;
      y = $question.offset().top - $cntWrap.offset().top;
      y += $question.innerHeight();
      y += 10; // offset
      this.$answer.css({
        'position' : 'absolute',
        'top'      : y
      });
      x  = $question.offset().left - $cntWrap.offset().left;
      x += $question.innerWidth() / 2;
      x -= this.$answer.innerWidth() / 2;
      x = x < 0 ? 0 : x;
      this.$answer.css({
        'left' : x
      });
    }
  }

  /**
   * 入力項目が押された時の処理
   */
  let questionClickHandler = function(e){
    // 答え合わせ済みなら何もしない
    if(this.$answer.hasClass('resulted')) return false;
    let $this = $(e.currentTarget);
    if(this.$activeQuestion) resetActiveQuestion.call(this);
    this.$answer.addClass('float');
    this.$activeQuestion = $this;
    this.$activeQuestion.addClass('active');

    // 回答の移動
    if(this.float){
      answerCtrl.pos.call(this, $this);
    }
  }
  /**
   * アクティブな入力項目のスタイルを元に戻す
   */
  let resetActiveQuestion = function(){
    if(this.$activeQuestion){
      this.$activeQuestion.removeClass('active');
      this.$activeQuestion = null;
    }

  }
  /**
   * 単語選択回答選択時のハンドラ
   */
  let answerClickHandler = function(e){
    let $this = $(e.currentTarget);

    // 単語が選択されている状態なら何もしない
    if($this.hasClass('checked')) return false;

    if(this.$activeQuestion.hasClass('has-val')){
      let num = this.$activeQuestion.attr('data-select-num');
      $(`li[data-num=${num}]`, this.$answer).removeClass('checked');
    };

    $this.addClass('checked');

    // 浮いている回答項目の処理
    if(this.float) answerCtrl.hide.call(this);

    let selectNum = $this.data('num');
    let inputText = $this.data('answer');
    let $input    = $('input', this.$activeQuestion);
    $input.val(inputText);
    this.$activeQuestion
      .attr('data-select-num', selectNum)
      .addClass('has-val');
    resetActiveQuestion.call(this);
  }
  /**
   * 入力のredoボタンクリック時のハンドラ
   */
  let inputRedoClickHandler = function(e){
    let $this   = $(e.currentTarget);
    let $parents = $this.parents(this.qSelector);
    let num      = $parents.attr('data-select-num');
    this.stateRemove(num);
  }


  // ==========================================================================
  //
  // TEXT QUESTION CLASS
  //
  // 以下のライブラリに依存
  //
  // - jQuery
  //
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;

  class SelectTextQuestion{
    /**
     * コンストラクタ
     */
    constructor($answer){
      this.qSelector        = '.d__practice--text-input';
      this.aSelector        = '.d__practice--text-answer';
      this.$answer          = $answer;
      this.$answerCloseBtn  = $('i.close', this.$answer);
      this.$answerWrapper   = this.$answer.parent(this.aSelector);
      this.float            = this.$answer.data('float') !== undefined ? this.$answer.data('float') : false;
      this.$answerBtn       = $('li', $answer);
      this.id               = this.$answer.data('answer-id');
      this.$question        = $(`${this.qSelector}[data-answer-id=${this.id}]`);
      this.answerNumList    = $($('input[type=hidden]', this.$answer));
      this.$activeQuestion  = null;

      // 入力項目に正解データを渡しておく
      let $question;
      let $input;
      let answerNum;
      let $rightAnswer;
      for(let i = 0, l = this.$question.length; i < l; i++){
        $question    = $(this.$question[i]);
        $input       = $('input', $question);
        answerNum    = $(this.answerNumList[i]).val();
        $rightAnswer = $(`li[data-num=${answerNum}]`, this.$answer);
        $('span.fail > span', $question).html($rightAnswer.html());
        $question.attr('data-answer-num', answerNum);
      }

      // イベント登録
      this.$question.on('click', (e)=>{
        questionClickHandler.call(this, e);
      });
      //
      if(this.float) this.$answerCloseBtn.on('click', (e)=>{
        answerCtrl.hide.call(this);
        resetActiveQuestion.call(this);
      });
      this.$answerBtn.on('click', (e)=>{
        answerClickHandler.call(this, e);
      });
      $('.redo', this.$question).on('click', (e)=>{
        inputRedoClickHandler.call(this, e);
      });
    }
    /** ------------------------------------------------------------------------
     *
     * 状態の初期化
     */
    stateRemove(num = false){
      let $question;
      let $input;
      let $answerBtn;
      if(num === false){
        $question  = this.$question;
        $input     = $('input', $question);
        $answerBtn = this.$answerBtn;
      }else{
        $question  = $(`${this.qSelector}[data-answer-id=${this.id}][data-select-num=${num}]`);
        $input     = $('input', $question);
        $answerBtn = $(`li[data-num=${num}]`, this.$answer);
      }
      $question
        .attr('data-select-num', '')
        .removeClass('has-val')
        .removeClass('fail')
        .removeClass('right');
      resetActiveQuestion.call(this);
      $input
        .val('');
      if(this.float) answerCtrl.hide.call(this);
      $answerBtn.removeClass('checked');
    }
    /** ------------------------------------------------------------------------
     *
     * やり直し
     */
    redo(){
      this.stateRemove();
      this.$answer.removeClass('resulted');
    }
    /** ------------------------------------------------------------------------
     *
     * 答え合わせ
     */
    result(){
      let $questions = this.$question;
      let $q;
      let state;
      resetActiveQuestion.call(this);
      for(let i = 0, l = $questions.length; i < l; i++){
        $q = $($questions[i]);
        if($q.attr('data-select-num') && $q.attr('data-select-num') === $q.attr('data-answer-num') ){
          state = 'right';
        }else{
          state = 'fail';
        }
        $q.addClass(state);
      };
      this.$answer.addClass('resulted');
    }
  }
  //
  _c.SelectTextQuestion = SelectTextQuestion;
})();

(()=>{

  /**
   *
   */
  let relationCtrl = {
    putDown : function(){
      this.observer.forEach((target, i)=>{
        if(this.id !== target.id){
            answerCtrl.hide.call(target);
        //   $(target.$answerBtn[index]).addClass('checked');
        }
      });
    },
    check : function(index){
      this.observer.forEach((target, i)=>{
        if(this.id !== target.id){
          $(target.$answerBtn[index]).addClass('checked');
        }
      });
    },
    uncheck : function(index){
      this.observer.forEach((target, i)=>{
        if(this.id !== target.id){
          $(target.$answerBtn[index]).removeClass('checked');
        }
      });
    },
    allRight : function(){
      let totalNum = this.observer.length;
      let count    = 0;
      this.observer.forEach((target, i)=>{
        if(target.$question.hasClass('allRighted')) count++;
      });
      if(totalNum !== count) return;
      this.observer.forEach((target, i)=>{
        target.$question.addClass('allRelationRighted');
      });
    }
  }

  /**
   *
   */
  let answerCtrl = {
    show : function(){
      this.$question.addClass('focus');
      this.$answerWrapper.addClass('float');
    },
    hide : function(){
      this.$question.removeClass('focus');
      this.$answerWrapper.removeClass('float');
      this.$answer.css({
        'position' : '',
        'top'      : '',
        'left'     : ''
      })
    },
    pos : function(){
      answerCtrl.show.call(this);
      let y,x;
      let $cntWrap = this.$question.parents('dd[data-js-anchor="cnt"]');
      y = this.$question.offset().top - $cntWrap.offset().top;
      y += this.$question.innerHeight();
      y += 10; // offset
      this.$answer.css({
        'position' : 'absolute',
        'top'      : y
      });
      x  = this.$question.offset().left - $cntWrap.offset().left;
      x += this.$question.innerWidth() / 2;
      x -= this.$answer.innerWidth() / 2;
      x = x < 0 ? 0 : x;
      this.$answer.css({
        'left' : x
      });
    }
  }

  /**
   * 単語選択回答選択時のハンドラ
   */
  let answerClickHandler = function(e){
    let $this = $(e.currentTarget);
    let num   = $this.attr('data-num');

    // 答え合わせ済みなら何もしない
    if(this.$answer.hasClass('resulted')) return false;

    // 単語が選択されている状態なら何もしない
    if($this.hasClass('checked')) return false;

    $this.addClass('checked');
    // リレーション状態なら他の回答もcheckedにする
    if(this.hasRelation) relationCtrl.check.call(this, $this.index());

    let $obj = this.$inputTemplate.clone();
    $obj.attr('data-select-num', num);
    $obj.addClass('has-val');
    $('.txt', $obj).html($this.attr('data-answer'));
    $('.redo', $obj).one('click', (e)=>{
      if(this.$answer.hasClass('resulted')) return false;
      inputRedoClickHandler.call(this, e);
    });
    // this.$question.append($obj);
    this.$questionCnt.append($obj);

    // 回答部分が浮いている状態の時だけの処理
    if(this.hasFloat){
      if($('li.checked', this.$answer).length === this.$answerBtn.length){
        answerCtrl.hide.call(this);
      }
    }
  }
  /**
   * 入力のredoボタンクリック時のハンドラ
   */
  let inputRedoClickHandler = function(e){
    let $this      = $(e.currentTarget);
    let $tag       = $this.parents('.tag');
    let num        = $tag.attr('data-select-num') * 1;
    let $answerBtn = $(`li[data-num=${num}]`, this.$answer);
    $answerBtn.removeClass('checked');
    if(this.hasRelation) relationCtrl.uncheck.call(this, $answerBtn.index());
    $tag.remove();
  }
  /**
   * 回答部分が移動する状態の時に入力部分をクリックしたときの処理
   */
  let inputClickWhenFloat = function(){

    // 答え合わせ済みなら何もしない
    if(this.$answer.hasClass('resulted')) return false;

    answerCtrl.pos.call(this);
    // リレーション状態にある他の要素が浮いている状態なら非表示にする
    if(this.hasRelation) relationCtrl.putDown.call(this);
  }
  /**
   * 回答部分が移動する状態の時に回答部分の閉じるボタンをクリックしたときの処理
   */
  let answerCloseWhenFloat = function(){
    answerCtrl.hide.call(this);
  }


  // ==========================================================================
  //
  // TEXT QUESTION CLASS
  //
  // 以下のライブラリに依存
  //
  // - jQuery
  //
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;

  class PermutationTextQuestion{
    /**
     * コンストラクタ
     */
    constructor($answer, observer = false){
      this.qSelector       = '.d__practice--text-input';
      this.aSelector       = '.d__practice--text-answer';
      this.$answer         = $answer;
      this.$answerWrapper  = this.$answer.parent('div');
      this.$answerBtn      = $('li', $answer);
      this.id              = this.$answer.attr('data-answer-id');
      this.$question       = $(`${this.qSelector}[data-answer-id=${this.id}]`);
      this.$questionCnt    = $('> div', this.$question);
      this.$answerNums     = $('input[type=hidden]', this.$answer);
      this.$answerCloseBtn = $('i.close', this.$answer);
      this.hasRelation     = false;
      this.$inputTemplate  = $(`<div class="tag">`);
      this.$inputTemplate.append('<span class="txt">');
      this.$inputTemplate.append('<i class="redo">');
      this.$inputTemplate.append('<span class="fail"><span></span></span>');

      this.hasFloat = this.$answer.data('float');

      this.observer = observer || false;
      if(this.observer){
        this.observer.push(this);
        this.hasRelation = true;
        this.$question.attr('data-relation', 'enable');
      }else{
        this.$question.attr('data-relation', 'disable');
      }

      if(this.hasFloat){
        this.$question.addClass('float');
      }

      // イベント登録
      this.$answerBtn.on('click', (e)=>{
        answerClickHandler.call(this, e);
      });

      // フロートがtrueの時のみのイベント
      if(this.hasFloat){
        this.$question.on('click', (e)=>{
          inputClickWhenFloat.call(this);
        });
        this.$answerCloseBtn.on('click', (e)=>{
          answerCloseWhenFloat.call(this);
        });
      }
    }
    /** ------------------------------------------------------------------------
     *
     * やり直し
     */
    redo(){
      answerCtrl.hide.call(this);
      this.$question.removeClass('allRighted');
      this.$question.removeClass('allRelationRighted');
      this.$questionCnt.empty();
      this.$answerBtn.removeClass('checked');
      this.$answer.removeClass('resulted');
    }
    /** ------------------------------------------------------------------------
     *
     * 答え合わせ
     */
    result(){
      let $tags   = $('.tag', this.$question);
      let hasFail = false;
      // 回答項目が足らない場合追加する
      if($tags.length < this.$answerNums.length){
        let l = this.$answerNums.length - $tags.length;
        for(let i = 0; i < l; i++){
          let $obj = this.$inputTemplate.clone();
          $('.txt', $obj).text('------');
          $obj.addClass('no-val');
          this.$questionCnt.append($obj);
        }
        $tags = $('.tag', this.$question);
      }
      $tags.each((i, tag)=>{
        let $tag      = $(tag);
        let selectNum = $tag.attr('data-select-num');
        let answerNum = $(this.$answerNums[i]).val() * 1;
        let state;
        if(selectNum !== undefined) selectNum = selectNum * 1;
        $('span.fail > span', $tag).html($(`li[data-num=${answerNum}]`, this.$answer).html());
        if(selectNum === answerNum){
          state = 'right';
        }else{
          state   = 'fail';
          hasFail = true;
        }
        $tag.addClass(state);
      });
      this.$answer.addClass('resulted');
      if(!hasFail) this.$question.addClass("allRighted");
      if(!hasFail && this.hasRelation) relationCtrl.allRight.call(this);
    }
  }
  //
  _c.PermutationTextQuestion = PermutationTextQuestion;
})();

(()=>{

  /**
   *
   */
  let relationCtrl = {
    unfocus : function(){
      if(!this.$activeQuestion) return false;

      this.$activeQuestion.removeClass('focus');

      let id = this.$activeQuestion.data('id');
      $(`*[data-q-relation=${id}]`).css({
        'border-bottom-width' : '',
        'border-bottom-style' : '',
        'border-bottom-color' : ''
      });
      this.$activeQuestion = null;
    }
    // putDown : function(){
    //   this.observer.forEach((target, i)=>{
    //     if(this.id !== target.id){
    //         answerCtrl.hide.call(target);
    //     //   $(target.$answerBtn[index]).addClass('checked');
    //     }
    //   });
    // },
    // check : function(index){
    //   this.observer.forEach((target, i)=>{
    //     if(this.id !== target.id){
    //       $(target.$answerBtn[index]).addClass('checked');
    //     }
    //   });
    // },
    // uncheck : function(index){
    //   this.observer.forEach((target, i)=>{
    //     if(this.id !== target.id){
    //       $(target.$answerBtn[index]).removeClass('checked');
    //     }
    //   });
    // },
    // allRight : function(){
    //   let totalNum = this.observer.length;
    //   let count    = 0;
    //   this.observer.forEach((target, i)=>{
    //     if(target.$question.hasClass('allRighted')) count++;
    //   });
    //   if(totalNum !== count) return;
    //   this.observer.forEach((target, i)=>{
    //     target.$question.addClass('allRelationRighted');
    //   });
    // }
  }

  /**
   *
   */
  // let answerCtrl = {
  //   show : function(){
  //     this.$question.addClass('focus');
  //     this.$answerWrapper.addClass('float');
  //   },
  //   hide : function(){
  //     this.$question.removeClass('focus');
  //     this.$answerWrapper.removeClass('float');
  //     this.$answer.css({
  //       'position' : '',
  //       'top'      : '',
  //       'left'     : ''
  //     })
  //   },
  //   pos : function(){
  //     answerCtrl.show.call(this);
  //     let y,x;
  //     let $cntWrap = this.$question.parents('dd[data-js-anchor="cnt"]');
  //     y = this.$question.offset().top - $cntWrap.offset().top;
  //     y += this.$question.innerHeight();
  //     y += 10; // offset
  //     this.$answer.css({
  //       'position' : 'absolute',
  //       'top'      : y
  //     });
  //     x  = this.$question.offset().left - $cntWrap.offset().left;
  //     x += this.$question.innerWidth() / 2;
  //     x -= this.$answer.innerWidth() / 2;
  //     x = x < 0 ? 0 : x;
  //     this.$answer.css({
  //       'left' : x
  //     });
  //   }
  // }

  /**
   * 単語選択回答選択時のハンドラ
   */
  let answerClickHandler = function(e){
    let $this = $(e.currentTarget);
    let num   = $this.attr('data-num');

    // 答え合わせ済みなら何もしない
    if(this.$answer.hasClass('resulted')) return false;

    // 単語が選択されている状態なら何もしない
    if($this.hasClass('checked')) return false;

    // 選択されている入力欄がなければ処理しない
    if(!this.$activeQuestion) return false;

    let limit    = this.$activeQuestion.attr('data-input-limit');
    let $cntWrap = $('> div', this.$activeQuestion);

    // 入力制限に達していれば何もしない
    if(limit > 0 && $('> *', $cntWrap).length >= limit) return false;

    $this.addClass('checked');

    let $obj = this.$inputTemplate.clone();

    $obj.attr('data-select-num', num);
    $obj.addClass('has-val');
    $('.txt', $obj).html($this.attr('data-answer'));

    $('.redo', $obj).one('click', (e)=>{
      if(this.$answer.hasClass('resulted')) return false;
      inputRedoClickHandler.call(this, e);
    });

    $cntWrap.append($obj);
    marugoto.global.practicePermutationBoxSizeCheck();
  }
  /**
   * 入力のredoボタンクリック時のハンドラ
   */
  let inputRedoClickHandler = function(e){
    let $this      = $(e.currentTarget);
    let $tag       = $this.parents('.tag');
    let num        = $tag.attr('data-select-num') * 1;
    let $answerBtn = $(`li[data-num=${num}]`, this.$answer);
    $answerBtn.removeClass('checked');
    $tag.remove();
  }
  /**
   * 入力部分をクリックしたときの処理
   */
  let inputClick = function($this){
    // 答え合わせ済みなら何もしない
    if(this.$answer.hasClass('resulted')) return false;

    relationCtrl.unfocus.call(this);

    let id = $this.data('id');
    $(`*[data-q-relation=${id}]`).css({
      'border-bottom-width' : '2px',
      'border-bottom-style' : 'solid',
      'border-bottom-color' : $(`*[data-q-relation=${id}]`).css('color') || '#ff6981'
    });

    this.$activeQuestion = $this;
    $this.addClass('focus');
  }
  /**
   * 回答部分が移動する状態の時に回答部分の閉じるボタンをクリックしたときの処理
   */
  // let answerCloseWhenFloat = function(){
  //   answerCtrl.hide.call(this);
  // }


  // ==========================================================================
  //
  // TEXT QUESTION CLASS
  //
  // 以下のライブラリに依存
  //
  // - jQuery
  //
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;

  class BoxPermutationTextQuestion{
    /**
     * コンストラクタ
     */
    constructor($answer, $wrap, observer = false){
      this.qSelector       = '.d__practice--text-input';
      this.aSelector       = '.d__practice--text-answer';
      this.$answer         = $answer;
      this.$answerBtn      = $('li', $answer);
      this.id              = this.$answer.attr('data-answer-id');
      this.questionIDs     = this.$answer.attr('data-relation-ids').split('__');
      let questionQuery    = '';
      this.questionIDs.forEach((val, i)=>{
        if(i !== 0) questionQuery += ',';
        questionQuery += `${this.qSelector}[data-id=${val}]`;
      });
      this.$question       = $(questionQuery);
      this.$questionCnt    = $('> div', this.$question);
      this.$activeQuestion = null;

      // 答えの設定
      this.answer       = {};
      this.$rightAnswer = $('input[type=hidden]', this.$answer);
      for(let i = 0, l = this.$rightAnswer.length; i < l; i++){
        let $t  = $(this.$rightAnswer[i]);
        let val = $t.val().split('__');
        let id  = val.shift();
        this.answer[id] = {
          'arr' : val,
          'str' : val.join('')
        };
      }
      for(let key in this.answer){
        $(`${this.qSelector}[data-id=${key}] span.fail span`).html(this.answer[key]['str']);
      }

      // 文字数制限の設定
      for(let i = 0, l = this.$question.length; i < l; i++){
        let $q = $(this.$question[i]);
        let id = $q.data('id');
        if(!$q.data('input-limit')){
          $q.attr('data-input-limit', -1);
        }else{
          $q.attr('data-input-limit', this.answer[id].arr.length);
        }
      }

      // 入力テンプレートの作成
      this.$inputTemplate  = $(`<div class="tag">`);
      this.$inputTemplate.append('<span class="txt">');
      this.$inputTemplate.append('<i class="redo">');
      // this.$inputTemplate.append('<span class="fail"><span></span></span>');
      //
      // -----------------------------------------------------------------------
      // イベント登録
      //
      this.$answerBtn.on('click', (e)=>{
        answerClickHandler.call(this, e);
      });
      this.$question.on('click', (e)=>{
        let $this = $(e.currentTarget);
        inputClick.call(this, $this);
      });
    }
    /** ------------------------------------------------------------------------
     *
     * やり直し
     */
    redo(){
      relationCtrl.unfocus.call(this);
      this.$question.removeClass('right');
      this.$question.removeClass('fail');
      // answerCtrl.hide.call(this);
      // this.$question.removeClass('allRighted');
      // this.$question.removeClass('allRelationRighted');
      this.$questionCnt.empty();
      this.$answerBtn.removeClass('checked');
      this.$answer.removeClass('resulted');
      marugoto.global.practicePermutationBoxSizeCheck();
    }
    /** ------------------------------------------------------------------------
     *
     * 答え合わせ
     */
    result(){
      let hasFail = false;
      for(let i = 0, l = this.$question.length; i < l; i++){
        let $q          = $(this.$question[i]);
        let $qCnt       = $('> div', $q);
        let $tag        = $('.tag', $qCnt);
        let id          = $q.data('id');
        let limit       = this.answer[id]['arr'].length;
        let rightAnswer = this.answer[id];
        let userAnswer  = '';
        let state;
        // 回答項目が足らない場合追加する
        if(limit > 0 && limit > $tag.length){
          let addNum = limit - $tag.length;
          while(addNum--){
            let $obj = this.$inputTemplate.clone();
            $('.txt', $obj).text('------');
            $obj.addClass('no-val');
            $qCnt.append($obj);
          }
        }
        $tag = $('.tag', $qCnt);
        for(let i = 0, l = $tag.length; i<l; i++){
          let keyword = $('.txt', $tag[i]).text();
          if(keyword === rightAnswer['arr'][i]){
            $($tag[i]).addClass('right');
          }else{
            $($tag[i]).addClass('fail');
          }
          userAnswer += keyword;
        }
        if(this.answer[id]['str'] === userAnswer){
          state = 'right';
        }else{
          state   = 'fail';
          hasFail = true;
        }
        $q.addClass(state);
      }
      // console.log(this.$question);
      // this.$question
      // let $tags   = $('.tag', this.$question);
      // let hasFail = false;
      // // 回答項目が足らない場合追加する
      // if($tags.length < this.$answerNums.length){
      //   let l = this.$answerNums.length - $tags.length;
      //   for(let i = 0; i < l; i++){
      //     let $obj = this.$inputTemplate.clone();
      //     $('.txt', $obj).text('------');
      //     $obj.addClass('no-val');
      //     this.$questionCnt.append($obj);
      //   }
      //   $tags = $('.tag', this.$question);
      // }
      // $tags.each((i, tag)=>{
      //   let $tag      = $(tag);
      //   let selectNum = $tag.attr('data-select-num');
      //   let answerNum = $(this.$answerNums[i]).val() * 1;
      //   let state;
      //   if(selectNum !== undefined) selectNum = selectNum * 1;
      //   $('span.fail > span', $tag).html($(`li[data-num=${answerNum}]`, this.$answer).html());
      //   if(selectNum === answerNum){
      //     state = 'right';
      //   }else{
      //     state   = 'fail';
      //     hasFail = true;
      //   }
      //   $tag.addClass(state);
      // });
      this.$answer.addClass('resulted');
      // if(!hasFail) this.$question.addClass("allRighted");
      // if(!hasFail && this.hasRelation) relationCtrl.allRight.call(this);
    }
  }
  //
  _c.BoxPermutationTextQuestion = BoxPermutationTextQuestion;
})();

(()=>{

  /**
   *
   */
  let imageClickHandler = function(e){
    let $this = $(e.currentTarget);

    if(this.$root.hasClass('resulted')) return false;

    if(this.$selectImg){
      this.$selectImg.removeClass('select');
      this.$selectImg = null;
    }
    this.$selectImg = $this;
    $this.addClass('select');
  }

  // ==========================================================================
  //
  // TEXT QUESTION CLASS
  //
  // 以下のライブラリに依存
  //
  // - jQuery
  //
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;

  class DefaultImageQuestion{
    /**
     * コンストラクタ
     */
    constructor($question){
      this.$root      = $question;
      this.answerNum  = this.$root.attr('data-answer-num') * 1;
      this.$img       = $('li', this.$root);
      this.$selectImg = null;

      // イベント登録
      this.$img.on('click', (e)=>{
        imageClickHandler.call(this, e);
      });
    }
    /** ------------------------------------------------------------------------
     *
     * やり直し
     */
    redo(){
      this.$selectImg = null;
      this.$root.removeClass('resulted');
      this.$img
        .removeClass('select')
        .removeClass('select-right')
        .removeClass('fail')
        .removeClass('right')
        .removeClass('resulted');
    }
    /** ------------------------------------------------------------------------
     *
     * 答え合わせ
     */
    result(){
      $(`li[data-num=${this.answerNum}]`, this.$root).addClass('right');
      if(this.$selectImg){
        let selectNum = this.$selectImg.attr('data-num') * 1;
        if(selectNum !== this.answerNum){
          this.$selectImg
            .removeClass('select')
            .addClass('fail');
        }
        if(selectNum === this.answerNum){
          this.$selectImg.addClass('select-right');
        }
      }
      this.$root.addClass('resulted');
      this.$img.addClass('resulted');
    }
  }
  //
  _c.DefaultImageQuestion = DefaultImageQuestion;
})();

(()=>{

  /**
   * 入力をクリックした時の処理
   */
  let inputClickHandler = function(e){
    let $this = $(e.currentTarget);

    // 答え合わせ後は処理しない
    if(this.$root.hasClass('resulted')) return false;

    if(this.$activeInput){
      this.$activeInput.removeClass('active');
      this.$activeInput = null;
    }

    this.$activeInput = $this;
    this.$activeInput.addClass('active');
  }
  /**
   * 入力のRedoボタンをクリックしたとくの処理
   */
  let inputRedoBtnClickHandler = function(e){
    let $this    = $(e.currentTarget);

    // 答え合わせ後は処理しない
    if(this.$root.hasClass('resulted')) return false;

    let $parents = $this.parents('li[data-key]');
    let key      = $parents.attr('data-key');
    $parents.removeClass('has-val');
    $('img', $parents).attr('src', '');
    $(`li[data-key=${key}]`, this.$answerRoot).removeClass('checked');
  }
  /**
   * 回答をクリックした時の処理
   */
  let answerClickHandler = function(e){
    let $this = $(e.currentTarget);

    // 答え合わせ後は処理しない
    if(this.$root.hasClass('resulted')) return false;
    // すでに選択されている場合は処理しない
    if($this.hasClass('checked')) return false;
    // アクティブなinput項目がない場合は処理しない
    if(!this.$activeInput) return false;

    // もしすでに選択状態であれば戻す
    if(this.$activeInput.hasClass('has-val')){
      let key = this.$activeInput.attr('data-key');
      $(`li[data-key=${key}]`, this.$answerRoot).removeClass('checked');
    }

    $this.addClass('checked');
    this.$activeInput
      .addClass('has-val')
      .attr('data-key', $this.attr('data-key'));
    $('img', this.$activeInput).attr('src', $('img', $this).attr('src'));
  }

  // ==========================================================================
  //
  // TEXT QUESTION CLASS
  //
  // 以下のライブラリに依存
  //
  // - jQuery
  //
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;

  class ClozeImageQuestion{
    /**
     * コンストラクタ
     */
    constructor($root){
      this.$root         = $root;
      this.$inputRoot    = $('> .input', this.$root);
      this.$inputLi      = $('li', this.$inputRoot);
      this.$answerRoot   = $('> .answer', this.$root);
      this.$answerLi     = $('li', this.$answerRoot);
      this.$inputRedoBtn = $('.redo', this.$inputLi);
      this.$activeInput  = null;

      // イベント登録
      this.$inputLi.on('click', (e)=>{
        inputClickHandler.call(this, e);
      });
      this.$answerLi.on('click', (e)=>{
        answerClickHandler.call(this, e);
      });
      this.$inputRedoBtn.on('click', (e)=>{
        inputRedoBtnClickHandler.call(this, e);
      });
    }
    /** ------------------------------------------------------------------------
     *
     * やり直し
     */
    redo(){
      this.$answerLi.removeClass('checked');
      this.$inputLi
        .removeClass('has-val')
        .removeClass('right')
        .removeClass('fail')
        .removeClass('active')
        .attr('data-key', '');
      this.$root.removeClass('resulted');
    }
    /** ------------------------------------------------------------------------
     *
     * 答え合わせ
     */
    result(){
      this.$inputLi.each((i, li)=>{
        let $li      = $(li);
        let val      = $li.attr('data-key');
        let rightVal = $('p.fail', $li).text();
        let state;
        if(val === rightVal){
          state = 'right';
        }else{
          state = 'fail';
        }
        $li
          .removeClass('active')
          .addClass(state);
      });
      this.$root.addClass('resulted');
    }
  }
  //
  _c.ClozeImageQuestion = ClozeImageQuestion;
})();

(()=>{

  let clickValHandler = function(e){
    if(!this.$root.hasClass('open')) return false;
    let $this = $(e.currentTarget);
    this.$li.removeClass('select');
    $this.addClass('select');
  }

  // ===========================================================================
  //
  // TEXT QUESTION CLASS
  //
  // 以下のライブラリに依存
  //
  // - jQuery
  //
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;

  class BooleanQuestion{
    /**
     * コンストラクタ
     */
    constructor($question){
      this.$root     = $question;
      this.answerNum = this.$root.attr('data-answer-num') * 1;
      this.$li       = $('li', this.$root);

      // イベント登録
      this.$root.on('click', (e)=>{
        if(this.$root.hasClass('resulted')) return false;
        let $this = $(e.currentTarget);
        if($this.hasClass('open')){
          $this.removeClass('open');
        }else{
          $this.addClass('open');
          $this.one('mouseleave', (e)=>{
            $this.removeClass('open');
          });
        }
      });
      //
      this.$li.on('click', (e)=>{
        clickValHandler.call(this, e);
      });

    }
    /** ------------------------------------------------------------------------
     *
     * やり直し
     */
    redo(){
      this.$li.removeClass('select');
      $(this.$li[0]).addClass('select');
      this.$root
        .removeClass('fail')
        .removeClass('right')
        .removeClass('resulted');
    }
    /** ------------------------------------------------------------------------
     *
     * 答え合わせ
     */
    result(){
      let $selectLi = $('li.select', this.$root);
      let num       = $selectLi.index();
      let state;
      if(num === this.answerNum){
        state = 'right';
      }else{
        state = 'fail';
      }
      this.$root
        .addClass(state)
        .addClass('resulted');
    }
  }
  //
  _c.BooleanQuestion = BooleanQuestion;
})();

(()=>{
  /**
   *
   * PRACTICE CLASS
   * 問題を管理するクラスページ上の(Q ...)単位。つまり問題セット
   *
   * 以下のライブラリに依存
   *
   * - jQuery
   *
   */
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;

  // ///////////////////////////////////////////////////////////////////////////
  //
  // CLASS
  //
  // ///////////////////////////////////////////////////////////////////////////
  class Practice{
    /**
     *
     */
    constructor($root){
      this.$root      = $root;
      this.$resultBtn = $('*[data-practice-btn-type="result"]', this.$root);
      this.$redoBtn   = $('*[data-practice-btn-type="redo"]',   this.$root);
      this.$rightCnt  = $('*[data-js-anchor="right-cnt"]',      this.$root);
      this.$rightCnt  = this.$rightCnt.length ? this.$rightCnt : false;
      this.observer   = {};
      this.questions  = [];

      let $textAnswer;
      let $image;
      let $bool;
      // -----------------------------------------------------------------------
      // 単語選択 -> 通常選択(type:default)
      //
      $textAnswer = $('.d__practice--text-answer > div[data-type=default]', this.$root);
      for(let i = 0, l = $textAnswer.length; i < l; i++){
        let textQ = new _c.DefaultTextQuestion($($textAnswer[i]));
        this.questions.push(textQ);
      }
      // -----------------------------------------------------------------------
      // 単語選択 -> 通常選択(type:select)
      //
      $textAnswer = $('.d__practice--text-answer > div[data-type=select]', this.$root);
      for(let i = 0, l = $textAnswer.length; i < l; i++){
        let textQ = new _c.SelectTextQuestion($($textAnswer[i]));
        this.questions.push(textQ);
      }
      // -----------------------------------------------------------------------
      // 単語選択 -> 順列選択(type:permutation)
      //
      this.observer.permutationRelation = [];
      $textAnswer = $('.d__practice--text-answer > div[data-type=permutation]', this.$root);
      for(let i = 0, l = $textAnswer.length; i < l; i++){
        let $target = $($textAnswer[i]);
        let textQ;
        if($target.data('relation')){
          textQ = new _c.PermutationTextQuestion($target, this.observer.permutationRelation);
        }else{
          textQ = new _c.PermutationTextQuestion($target);
        }
        this.questions.push(textQ);
      }
      // -----------------------------------------------------------------------
      // 単語選択 -> 順列選択(type:permutation)
      //
      // this.observer.permutationBoxRelation = [];
      $textAnswer = $('.d__practice--text-answer > div[data-type=permutation-box]', this.$root);
      for(let i = 0, l = $textAnswer.length; i < l; i++){
        let $target = $($textAnswer[i]);
        let textQ   = new _c.BoxPermutationTextQuestion($target, $target.parents('.d__practice-permutation-box'));
        // if($target.data('relation')){
        //   textQ = new _c.PermutationTextQuestion($target, this.observer.permutationRelation);
        // }else{
        //   textQ = new _c.PermutationTextQuestion($target);
        // }
        this.questions.push(textQ);
      }
      // -----------------------------------------------------------------------
      // 画像選択 -> 通常選択(type:default)
      //
      $image = $('.d__practice--image[data-type=default]', this.$root);
      for(let i = 0, l = $image.length; i < l; i++){
        let image = new _c.DefaultImageQuestion($($image[i]));
        this.questions.push(image);
      }
      $image = $('.d__practice--image[data-type=cloze]', this.$root);
      for(let i = 0, l = $image.length; i < l; i++){
        let image = new _c.ClozeImageQuestion($($image[i]));
        this.questions.push(image);
      }
      // -----------------------------------------------------------------------
      // 真偽選択
      //
      $bool = $('.d__practice--bool', this.$root);
      for(let i = 0, l = $bool.length; i < l; i++){
        let bool = new _c.BooleanQuestion($($bool[i]));
        this.questions.push(bool);
      }
      // -----------------------------------------------------------------------
      // ボタンイベント
      // -----------------------------------------------------------------------
      // やおりなおし
      this.$redoBtn.on('click', (e)=>{
        e.preventDefault();
        this.redo();
      });
      // 答え合わせ
      this.$resultBtn.on('click', (e)=>{
        e.preventDefault();
        this.result();
      });
    }
    /**
     * =========================================================================
     * やりなおし
     */
    redo(){
      this.questions.forEach((q, i)=>{
        q.redo();
        if(this.$rightCnt) this.$rightCnt.css('display', 'none');
      });
    }
    /**
     * =========================================================================
     * 答え合わせ
     */
    result(){
      this.questions.forEach((q, i)=>{
        q.result();
        if(this.$rightCnt) this.$rightCnt.css('display', 'block');
      });
    }
  }
  _c.Practice = Practice;
})();

(()=>{
  /**
   *
   * MODAL CLASS
   *
   * 以下のライブラリに依存しています。
   *
   * - jQuery
   *
   * @param {jQuery} - 親要素となるjQueryオブジェクト
   */
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;
  let $win              = null;
  let $body             = null;
  let _private          = new WeakMap();
  let get_              = (that)=>{
      return _private.get(that);
  };
  let instance = null;

  // modalの幅と高さをwindowに合わせる
  let fix2win = function(){
    let _ = get_(this);
    _.$cntr.css({
      width  : $win.innerWidth(),
      height : $win.innerHeight()
    });
  };
  // data-modal-size属性をオブジェクトに変える
  let sizeDataFormat = function(size){
    size = size
      .trim()
      .replace(/^([a-z0-9\-\_]*) *: */i, '"$1":')
      .replace(/] *\, *([a-z0-9\-\_]*) *: */i, '],"$1":')
      .replace(/\'/g, '\"');
    return JSON.parse(`{${size}}`);
    return true;
  };
  //
  let cntPosSizeFix = function(){
    let _ = get_(this);
    let displaySize = _.pageData.state.displaySize;
    if(!displaySize) return;
    if(!_.ops.size || !_.ops.size[displaySize]) return false;
    _.$cntWrap.css({
      'width'  : _.ops.size[displaySize][0],
      'height' : _.ops.size[displaySize][1],
      'top'    : '50%',
      'left'   : '50%'
    });
    _.$cntWrap.css({
      'margin-left' : `${_.$cntWrap.width() / -2}px`,
      'margin-top'  : `${_.$cntWrap.height() / -2}px`
    });
  }

  /**
   *
   */
  let eventTrigger = function(eventType, ops){
    let e = {};
    e.$root = this.$root;
    e.$cnt  = this.$cnt;
    e.type  = eventType;
    if(eventType === 'show'){
      e.addCnt = ops.addCnt;
    }
    if(eventType === 'triggerClick'){
      e.target        = ops.target;
      e.currentTarget = ops.currentTarget;
    }
    for(let key in this.listener[eventType]){
      typeof this.listener[eventType][key].call(this, e);
    }
  }


  // ///////////////////////////////////////////////////////////////////////////
  //
  // CLASS
  //
  // ///////////////////////////////////////////////////////////////////////////
  class Modal{
    /**
     * =========================================================================
     * コンストラクタ
     * =========================================================================
     * @param  {object} listener -
     * @return {Modal}
     */
    constructor(pageData){
      $win  = $(window);
      $body = $('body');
      if(instance) return instance;
      _private.set(this, Object.create(null));
      let _         = get_(this);
      let className = 'm__modal';
      // -----------------------------------------------------------------------
      _.$cntr         = $('.m__modal');              //
      _.$cntWrap      = $('> div', _.$cntr);         //
      _.$cnt          = $('div.cnt > div', _.$cntr); //
      _.$clsArea      = $('.close', _.$cntr);        //
      _.eventListener = pageData.eventListener;      //
      _.ops           = {};                          //
      _.$clicked      = null;                        //
      _.pageData      = pageData;
      // -----------------------------------------------------------------------
      this.listener              = window.marugoto.eventListener.modal || {};
      this.listener.triggerClick = {};
      this.listener.show         = {};
      this.listener.close        = {};
      this.$root                 = _.$cntr;
      this.$cnt                  = _.$cnt;
      // -----------------------------------------------------------------------
      // イベントハンドラ

      // リンククリック
      let linkClick = (e)=>{
        e.preventDefault();
        let $this  = $(e.currentTarget);
        _.$clicked = $this;
        eventTrigger.call(this, 'triggerClick', {
          'target'        : e.target,
          'currentTarget' : e.currentTarget
        });
        this.show();
      }
      // 閉じるボタンや背景をクリック
      let closeModal = (e)=>{
        this.hide();
      }
      // -----------------------------------------------------------------------
      // イベント登録
      //
      // リンククリック
      $(document).on('click', 'a[data-modal-url], a[data-modal], a[data-modal-selector]', linkClick);
      // 閉じる処理
      _.$clsArea.on('click', (e)=>{ closeModal(e) });
      _.$cntr.on('click', (e)=>{
        let $this = $(e.target);
        if($this.hasClass(className)) closeModal(e);
      });
      // リサイズ
      _.eventListener.resize.modal = (e)=>{
        fix2win.call(this);
        if(_.$cntr.hasClass('active')) cntPosSizeFix.call(this, e);
      }
      // ブレイク時
      _.eventListener.break.modal = (e)=>{
        fix2win.call(this);
        if(_.$cntr.hasClass('active')) cntPosSizeFix.call(this, e);
      }

      instance = this;


    }
    /**
     * モーダルの表示
     */
    show($clicked = false){
      let _ = get_(this);
      if($clicked) _.$clicked = $clicked;
      let $this = _.$clicked;
      let type  = $this.data('modal-url') ? 'url' : $this.data('modal-selector') ? 'selector' : 'content';
      let addCnt;
      _.ops = {
        'size' : $this.data('modal-size') ? sizeDataFormat.call(this, $this.data('modal-size')) : false
      };
      // -----------------------------------------------------------------------
      // URLの場合
      //
      if(type === 'url'){
        addCnt = $('<iframe>');
        addCnt.attr({
          'name' : 'modal-frame',
          'src'  : $this.data('modal-url')
        });
      }
      // -----------------------------------------------------------------------
      // セレクタの場合
      //
      else if(type === 'selector'){
        addCnt = $($this.data('modal-selector')).clone(true);
      }
      // -----------------------------------------------------------------------
      // コンテンツの場合
      //
      else{
        addCnt = $('<div>');
        addCnt.addClass('add-cnt');
        let addData = $this.data('modal');
        addData = addData.replace(/< *\/? *script *[a-z0-9\_\-\*\.\/\'\"]* *>/gi, '');
        addData = addData.replace(/< *\/? *iframe *[a-z0-9\_\-\*\.\/\'\"]* *>/gi, '');
        addCnt.html(addData);
      }

      if(addCnt){
        _.$cnt.html(addCnt);
        _.$cntr.addClass('active');
        cntPosSizeFix.call(this);
      }
      eventTrigger.call(this, 'show', {addCnt : addCnt});
    }
    /**
     * モーダルの非表示
     */
    hide(){
      let _ = get_(this);
      _.$cntr.removeClass('active');
      _.$cnt.empty();
      _.$cntWrap.css({
        'width'       : '',
        'height'      : '',
        'top'         : '',
        'left'        : '',
        'margin-left' : '',
        'margin-top'  : ''
      });
      _.ops = {};
      _.$clicked = null;
      eventTrigger.call(this, 'close');
    }
  }
  _c.Modal = Modal;
})();

(()=>{
  /**
   *
   * KANJI CARD CLASS
   *
   * 以下のライブラリに依存しています。
   *
   * - jQuery
   *
   * @param {jQuery} - 親要素となるjQueryオブジェクト
   */
  window.marugoto.class = window.marugoto.class || {};
  let _c                = window.marugoto.class;

  /**
   *
   */
  let shuffle = function(arr){
    let n = arr.length;
    let t;
    let i;
    while(n){
      i = Math.floor(Math.random() * n--);
      t = arr[n];
      arr[n] = arr[i];
      arr[i] = t;
    }
    return arr;
  }

  // ///////////////////////////////////////////////////////////////////////////
  //
  // CLASS
  //
  // ///////////////////////////////////////////////////////////////////////////
  class KanjiCard{
    /**
     * =========================================================================
     * コンストラクタ
     * =========================================================================
     */
    constructor($root){
      this.$root           = $root;
      this.$section        = $('> section', $root);
      this.$shuffleSection = this.$section.clone(true);

      this.$section.remove();
      let _secArr        = [];
      let secArr         = [];
      let secShuffNumArr = [];
      this.$shuffleSection.each((i, obj)=>{
        secShuffNumArr.push(i);
        _secArr.push(obj);
      });
      secShuffNumArr = shuffle(secShuffNumArr);
      secShuffNumArr.forEach((val, i)=>{
        secArr.push(_secArr[val]);
      });
      secArr.forEach((val, i)=>{
        this.$root.append(val);
      });
      this.$section       = $('> section', $root);
      this.$activeSection = $(this.$section[0]);
      this.sectionNum     = this.$section.length;
      this.$resultBtn     = $('.kanji-result-btn, .yomi-result-btn', $root);
      this.$nextBtn       = $('.next-btn', $root);
      this.$returnBtn     = $('.return-btn img', $root);
      let $typeSelectBtn  = $('> header li img', $root);

      // イベント
      // 漢字から出すか読みから出すか
      $typeSelectBtn.on('click', (e)=>{
        let $this   = $(e.currentTarget);
        let $parent = $this.parent('li');
        let type;
        if($parent.hasClass('kanji-start-btn')){
          type = 'kanji';
        }
        else if($parent.hasClass('yomi-start-btn')){
          type = 'yomi';
        }
        this.$root
          .addClass('started')
          .attr('data-type', type);
        $(this.$activeSection).addClass('active');
      });
      // 回答表示
      this.$resultBtn.on('click', (e)=>{
        let $this = $(e.currentTarget);
        this.$activeSection.addClass('result');
        if(this.sectionNum === this.$activeSection.index()) this.$root.addClass('finish');
      });
      // 次の問題
      this.$nextBtn.on('click', (e)=>{
        this.$activeSection
          .removeClass('result')
          .removeClass('active');
        this.$activeSection = this.$activeSection.next('section');
        this.$activeSection.addClass('active');
      });
      // 最初に戻る
      this.$returnBtn.on('click', (e)=>{
        this.$section
          .removeClass('active')
          .removeClass('result');
        this.$root
          .removeClass('finish')
          .removeClass('started')
          .attr('data-type', '');
        this.$activeSection = $(this.$section[0]);
      });
    }
  }
  _c.KanjiCard = KanjiCard;
})();
